<template>
  <main class="container pb-16 pb-lg-32 px-lg-32">
    <div class="mb-8" v-if="schedule">
      <ScheduleInfo :schedule="schedule" />
    </div>

    <Table
      rowKey="id"
      :isLoading="$store.getters['students/isFetching'](params)"
      :columns="columns"
      :rows="$store.getters['students/get'](params)"
    />
  </main>
</template>

<script>
import Meeting from '../../../../store/models/Meeting';
import Schedule from '../../../../store/models/Schedule';

import ScheduleInfo from '../../../../components/student/schedule/ScheduleInfo.vue';
import Table from '../../../../components/table/Table.vue';

export default {
  components: { ScheduleInfo, Table },

  computed: {
    params() {
      return {
        $repositoryMethod: 'fetchByMeetingId',
        $repositoryParams: [this.$route.params.meeting_id],
      };
    },

    meeting() {
      return Meeting.query()
        .withAllRecursive()
        .find(this.$route.params.meeting_id);
    },

    schedule() {
      return Schedule.query()
        .withAllRecursive()
        .find(this.$route.params.schedule_id);
    },

    columns() {
      return [
        { key: 'user.name', text: 'Nama' },
        { key: 'nisn', text: 'NISN' },
        { key: 'nis', text: 'NIS' },
        {
          key: 'attended',
          text: 'Status',
          toHTML: (row) =>
            row.attendances.length
              ? '<span class="text-success">Hadir</span>'
              : '<span class="text-danger">Tidak Hadir</span>',
        },
        {
          key: 'attended_at',
          text: 'Jam Masuk',
          toHTML: (row) => {
            const attendance = row.attendances[0];
            return attendance ? attendance.createdAtJS.format('HH:mm') : null;
          },
        },
      ];
    },
  },

  metaInfo() {
    return { title: 'Presensi' };
  },

  async beforeMount() {
    if (!this.schedule) {
      await this.$store.dispatch('schedules/maybeFetch', {
        filter: { id: this.$route.params.schedule_id },
      });
    }

    if (!this.meeting) {
      this.$store.dispatch('meetings/maybeFetch', {
        filter: { id: this.$route.params.meeting_id },
      });
    }

    this.$store.dispatch('students/fetch', this.params);

    this.$store.commit('info/setBreadcrumb', [
      { path: '/guru/jadwal', label: 'Jadwal' },
      {
        path: this.schedule.scheduleRoutePath,
        label: this.schedule.subject.name,
      },
      {
        path: this.schedule && this.schedule.meetingsRoutePath,
        label: 'Pertemuan',
      },
    ]);
  },
};
</script>
